import { loginUserReducer } from 'src/modules/login/reducers/loginUserReducer';
import { userSettingsReducer } from 'src/modules/login/reducers/userSettingsReducer';
import { LoginState } from 'src/modules/login/types/LoginState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: LoginState = {
  user: null,
};

export const loginReducer = chainReducers(defaultState, [
  loginUserReducer,
  userSettingsReducer,
]);
