import { HtmlNode, parse as parseHtmlToPdf } from '@belimo-retrofit-portal/html2pdf/lib/parse';
import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { doOnPdfWorker } from 'src/sagas/utils/doOnPdfWorker';
import { getImageContent } from 'src/sagas/utils/getImageContent';
import { HtmlToPdfWorkerRequest } from 'src/types/worker/HtmlToPdfWorker';
import { all, call } from 'typed-redux-saga';

export function* htmlToPdf(
  pages: ReadonlyArray<Element>,
): SagaIterator<Blob> {
  const nodes: HtmlNode[] = [];
  for (const child of pages) {
    const pageNode = yield* call(parseHtmlToPdf, child);
    nodes.push(pageNode);
  }

  const imageUrls = yield* call(getImageUrls, nodes);
  const imageData = yield* all(imageUrls.map((url) => callEffect(getImageContent, url)));
  const resources = new Map(imageUrls.map((url, index) => [url, imageData[index]]));

  const workerRequest: HtmlToPdfWorkerRequest = { nodes, resources };
  return yield* call(doOnPdfWorker, workerRequest);
}

function getImageUrls(nodes: ReadonlyArray<HtmlNode>): string[] {
  const imageUrls: string[] = [];
  for (const node of nodes) {
    if (node.type === 'block') {
      imageUrls.push(...getImageUrls(node.children));
    }
    if (node.type === 'image') {
      imageUrls.push(node.imageSource);
    }
  }

  return imageUrls;
}
