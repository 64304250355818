import './FileUpload.scss';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { isValidImageData } from 'src/modules/asset/utils/isValidImageData';
import { FILE_MAX_SIZE_FILED_MB } from 'src/modules/common/constants/fileField';
import { UploadIcon } from 'src/modules/common/icons/UploadIcon';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';

type FileError =
  | 'file-too-large'
  | 'file-invalid-type';

type Props = {
  readonly id?: string;
  readonly name?: string;
  readonly disabled: boolean;
  readonly children: React.ReactNode;

  readonly onTouch: () => void;
  readonly onChange: (file: File) => void;
};

export const FileUpload = ({
  id,
  name,
  onTouch,
  onChange,
  disabled,
  children,
}: Props): React.ReactElement => {
  const [fileError, setFileError] = useState<ErrorCode | null>(null);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      isValidImageData(file).then((valid) => {
        if (valid) {
          setFileError(null);
          onChange(file);
        } else {
          setFileError(ErrorCode.FileInvalidType);
        }
      }, sentryCatch);
    }
  }, [onChange]);

  const handleReject = (rejectData: FileRejection[]): void => {
    const rejection = rejectData[0];

    /* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
    if (rejection.errors.some((error) => error.code === ErrorCode.FileTooLarge)) {
      setFileError(ErrorCode.FileTooLarge);
    } else if (rejection.errors.some((error) => error.code === ErrorCode.FileInvalidType)) {
      setFileError(ErrorCode.FileInvalidType);
    }
    /* eslint-enable @typescript-eslint/no-unsafe-enum-comparison */
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    onFileDialogOpen: onTouch,
    onDropRejected: handleReject,
    disabled: disabled,
    maxSize: FILE_MAX_SIZE_FILED_MB * 1024 * 1024, // 10 MB in bytes
    accept: {
      'image/webp': ['.webp'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
  });

  useEffect(() => {
    if (fileError !== null) {
      const timer = setTimeout(() => {
        setFileError(null);
      }, 3000);

      return () => clearTimeout(timer);
    }

    return undefined;
  }, [fileError]);

  return (
    <div
      {...getRootProps()}
      className={clsx(
        'bp-file-upload',
        disabled ? 'bp-file-upload--disabled' : null,
      )}
      data-testid="file-upload"
    >
      <input
        id={id}
        name={name}
        {...getInputProps()}
      />

      <div className="bp-file-upload__drop-area">
        <UploadIcon/>

        {fileError === ErrorCode.FileTooLarge
          ? <DropdownWarningFileTooLarge/>
          : fileError === ErrorCode.FileInvalidType
            ? <DropdownWarningInvalidFormat/>
            : children}
      </div>
    </div>
  );
};

const DropdownWarningFileTooLarge = (): React.ReactElement => (
  <p className="bp-file-upload__warning">
    <FormattedMessage id="form/file/field/error/size" values={{ maxSize: FILE_MAX_SIZE_FILED_MB }}/>
  </p>
);

const DropdownWarningInvalidFormat = (): React.ReactElement => (
  <p className="bp-file-upload__warning">
    <FormattedMessage id="form/file/field/error/invalidImage"/>
  </p>
);
