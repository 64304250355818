import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { emulateTransitionDelay } from 'src/modules/routing/hooks/emulateTransitionDelay';
import { abortProjectList } from 'src/modules/routing/hooks/resolvers/abortProjectList';
import { fetchProjectDetails } from 'src/modules/routing/hooks/resolvers/fetchProjectDetails';
import { fetchProjectList } from 'src/modules/routing/hooks/resolvers/fetchProjectList';
import { fetchSchemaSelection } from 'src/modules/routing/hooks/resolvers/fetchSchemaSelection';
import { resetProjectDetails } from 'src/modules/routing/hooks/resolvers/resetProjectDetails';
import { resetProjectList } from 'src/modules/routing/hooks/resolvers/resetProjectList';
import { syncProjectDetails } from 'src/modules/routing/hooks/resolvers/syncProjectDetails';
import { createStateTransitionLoggingSaga } from 'src/modules/routing/hooks/stateTransitionLogging';
import { RouteState } from 'src/modules/routing/types/RouteState';
import { composeSagas } from 'src/modules/routing/utils/composeSagas';

export const ROUTING_PROJECT_STATES: ReadonlyArray<RouteState> = [
  {
    url: '/projects',
    name: 'bp:project:list',
    parent: 'bp:authorized',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
      fetchProjectList,
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
      abortProjectList,
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
      abortProjectList,
      fetchProjectList,
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
      resetProjectList,
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
      abortProjectList,
    ),
  },
  {
    url: '/project/:id([-a-f0-9]+)',
    name: 'bp:project:view',
    parent: 'bp:authorized',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
      fetchProjectDetails,
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      scheduleUpdateLatestActivity,
      scheduleUpdateFeedbackCounter,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
      resetProjectDetails,
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
  {
    url: '',
    name: 'bp:project:basedata',
    parent: 'bp:project:view',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
      syncProjectDetails,
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      scheduleUpdateLatestActivity,
      scheduleUpdateFeedbackCounter,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
  {
    url: '/hvac',
    name: 'bp:project:hvac',
    parent: 'bp:project:view',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 200),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
      syncProjectDetails,
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      scheduleUpdateLatestActivity,
      scheduleUpdateFeedbackCounter,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },

  {
    url: '/schema',
    name: 'bp:project:schema',
    parent: 'bp:project:view',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
      fetchSchemaSelection,
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
      syncProjectDetails,
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      scheduleUpdateLatestActivity,
      scheduleUpdateFeedbackCounter,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },

  {
    url: '/evaluation',
    name: 'bp:project:evaluation',
    parent: 'bp:project:view',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
      syncProjectDetails,
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      scheduleUpdateLatestActivity,
      scheduleUpdateFeedbackCounter,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },

  {
    url: '/report',
    name: 'bp:project:report',
    parent: 'bp:project:view',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
      syncProjectDetails,
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      scheduleUpdateLatestActivity,
      scheduleUpdateFeedbackCounter,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },

  {
    url: '/report/document',
    name: 'bp:project:report-document',
    parent: 'bp:project:view',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      scheduleUpdateLatestActivity,
      scheduleUpdateFeedbackCounter,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
];
