import * as C from 'io-ts/Codec';
import { ProjectReportCoverContactsEnum } from 'src/modules/common/types/ProjectReportCoverContacts';

export const USER_SETTINGS = C.struct({
  report: C.struct({
    showOrganizationLogo: C.boolean,
    coverContacts: C.literal(
      ProjectReportCoverContactsEnum.OFFICE,
      ProjectReportCoverContactsEnum.ORGANIZATION,
    ),
  }),
});
