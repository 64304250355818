import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { ANNOUNCEMENTS_FETCH } from 'src/modules/announcements/actions/AnnouncementsActions';
import { readAnnouncement } from 'src/modules/announcements/sagas/utils/readAnnouncement';
import {
  getAnnouncementState,
} from 'src/modules/announcements/selectors/getAnnouncementState';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { all, call, put, select } from 'typed-redux-saga';

export function* readAnnouncements(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Reading announcements...');

    const announcementState = yield* select(getAnnouncementState);

    const unreadAnnouncements = announcementState.announcements.filter((announcement) => !announcement.read);

    yield* all(unreadAnnouncements.map(
      (unreadAnnouncement) => callEffect(readAnnouncement, unreadAnnouncement.id),
    ));

    yield* put(ANNOUNCEMENTS_FETCH.trigger(announcementState.announcements.map(
      (announcement) => ({
        ...announcement,
        read: true,
      }),
    )));
    yield* call(logDebug, 'Reading announcements... done');

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(logError, 'Fetching announcements... error', error);
    yield* call(sentryCatch, error);
    yield* call(showNotification, { variant: 'error', type: 'error', messageId: 'announcements/readError' });
  }
}
