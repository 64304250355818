import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { AUTH_USER } from 'src/modules/common/codecs/AuthUser';
import { AuthUser } from 'src/modules/common/types/AuthUser';
import { call } from 'typed-redux-saga';

export function* updateFeedbackCounter(): SagaIterator<AuthUser> {
  try {
    const data = yield* call(
      makeRestRequest,
      'PUT',
      '/account/update-feedback-counter',
    );

    return decodeOrThrow(AUTH_USER, data.content);
  } catch (error) {
    throw new RuntimeError('Could not update feedback counter', {}, NativeError.wrap(error));
  }
}
