import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { useParametricSelector } from 'src/hooks/useParametricSelector';
import { ProjectToolbar } from 'src/modules/common/components/ProjectToolbar';
import { AppLayout } from 'src/modules/common/layouts/AppLayout';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { UPDATE_USER_SETTINGS } from 'src/modules/login/actions/UserSettingsActions';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { PROJECT_VIEW_SHARE } from 'src/modules/project-view/actions/ProjectViewActions';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { REPORT_RESET } from 'src/modules/report/actions/ReportActions';
import { getReportFormData } from 'src/modules/report/selectors/getReportFormData';
import { getReportGenerated } from 'src/modules/report/selectors/getReportGenerated';
import { getReportLoading } from 'src/modules/report/selectors/getReportLoading';
import { ReportFormView } from 'src/modules/report/views/ReportFormView';
import { ShareProject } from 'src/modules/sharing-projects/containers/ShareProject';

export const ReportPage = (): React.ReactElement => {
  const intl = useIntl();
  const project = useSelector(getProjectDetails);
  const language = useSelector(getLanguage);
  const document = useParametricSelector(getReportGenerated, project);
  const formData = useParametricSelector(getReportFormData, project);
  const loading = useSelector(getReportLoading);
  const loggedInUser = useSelector(getLoggedInUser);

  const resetState = useAction(REPORT_RESET.trigger);
  const updateUserSettings = useAction(UPDATE_USER_SETTINGS.trigger);

  useEffect(() => () => resetState(), [resetState, project.id]);

  const shareProject = useBoundAction(PROJECT_VIEW_SHARE.trigger, project);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'report/pageTitle' })}
        </title>
      </Helmet>

      <AppLayout>
        <ProjectToolbar
          project={project}
          onShare={shareProject}
        />
        <ReportFormView
          project={project}
          document={document}
          formData={formData}
          loading={loading}
          language={language}
          userSettings={loggedInUser.settings}
          useOrganizationContacts={loggedInUser.organization.useOrganizationContacts}
          updateUserSettings={updateUserSettings}
        />
      </AppLayout>

      <ShareProject/>
    </>
  );
};
