import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { AUTH_USER } from 'src/modules/common/codecs/AuthUser';
import { USER_SETTINGS } from 'src/modules/common/codecs/UserSettings';
import { AuthUser } from 'src/modules/common/types/AuthUser';
import { UserSettings } from 'src/modules/common/types/UserSettings';
import { call } from 'typed-redux-saga';

export function* updateUserSettings(userSettings: UserSettings): SagaIterator<AuthUser> {
  try {
    const body = yield* call(USER_SETTINGS.encode, userSettings);
    const { content } = yield* call(makeRestRequest, 'PUT', '/account/settings', body);
    return yield* call(decodeOrThrow, AUTH_USER, content);
  } catch (error) {
    throw new RuntimeError('Could not update user settings', { userSettings }, NativeError.wrap(error));
  }
}
