import './FeedbackButton.scss';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { MailIcon } from 'src/modules/common/icons/MailIcon';
import { FEEDBACK_OPEN, FEEDBACK_RESET } from 'src/modules/feedback/actions/FeedbackActions';
import { getFeedbackCounter } from 'src/modules/feedback/selectors/getFeedbackCounter';
import { getFeedbackModal } from 'src/modules/feedback/selectors/getFeedbackModal';
import { FeedbackModal } from 'src/modules/feedback/views/FeedbackModal';

export const FeedbackButton = (): React.ReactElement => {
  const feedbackReset = useAction(FEEDBACK_RESET.trigger);
  const feedbackModalOpen = useAction(FEEDBACK_OPEN.trigger);

  const handleOpen = useCallback(() => feedbackModalOpen(true), [feedbackModalOpen]);
  const handleClose = useCallback(() => {
    feedbackModalOpen(false);
    feedbackReset();
  }, [feedbackModalOpen, feedbackReset]);

  const feedbackModalOpened = useSelector(getFeedbackModal);

  return (
    <>
      <div className="bp-feedback-button">
        <CustomButton
          className="bp-feedback-button__button"
          kind="secondary"
          size="sm"
          onClick={handleOpen}
        >
          <div className="bp-feedback-button__button-icon">
            <MailIcon/>
          </div>

          <FormattedMessage id="feedback/button"/>
        </CustomButton>
      </div>

      <CustomModal
        className="bp-feedback-modal"
        open={feedbackModalOpened}
        onClose={handleClose}
        isShaded={true}
        isNarrowFooter={true}
        size="md"
        shouldUnmount={true}
      >
        <FeedbackModal onClose={handleClose}/>
      </CustomModal>
    </>
  );
};
