import { COUNTRY_CODE, UUID } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { ASSET } from 'src/modules/common/codecs/Asset';
import { Organization } from 'src/modules/common/types/Organization';
import { OrganizationAddress } from 'src/modules/common/types/OrganizationAddress';
import { OrganizationContact } from 'src/modules/common/types/OrganizationContact';
import { OrganizationOffice } from 'src/modules/common/types/OrganizationOffice';

export const ORGANIZATION_ADDRESS: C.Codec<unknown, Json, OrganizationAddress> = C.struct({
  country: C.nullable(COUNTRY_CODE),
  city: C.string,
  zip: C.string,
  street: C.string,
});

export const ORGANIZATION_CONTACT: C.Codec<unknown, Json, OrganizationContact> = C.struct({
  email: C.string,
  phone: C.string,
  website: C.string,
});

export const ORGANIZATION_OFFICE: C.Codec<unknown, Json, OrganizationOffice> = C.struct({
  name: C.string,
  shop: C.string,

  address: ORGANIZATION_ADDRESS,
  contact: ORGANIZATION_CONTACT,
});

export const ORGANIZATION: C.Codec<unknown, Json, Organization> = C.struct({
  id: UUID,
  name: C.string,
  logo: C.nullable(ASSET),

  address: ORGANIZATION_ADDRESS,
  contact: ORGANIZATION_CONTACT,

  useOrganizationContacts: C.boolean,
  office: ORGANIZATION_OFFICE,
});
