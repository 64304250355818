import { DATE_TIME, UUID } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { USER_ROLE } from 'src/modules/common/codecs/UserRole';
import { User } from 'src/modules/users/types/User';

export const ORGANIZATION_USER: C.Codec<unknown, Json, User> = C.struct({
  createdAt: DATE_TIME,
  email: C.string,
  firstName: C.string,
  id: UUID,
  lastName: C.string,
  role: USER_ROLE,
  latestActivity: C.nullable(DATE_TIME),
  feedbackCounter: C.number,
});
