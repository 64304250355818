import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { PROFILE_CHANGE_PASSWORD } from 'src/modules/profile/actions/ProfileActions';
import { CHANGE_PASSWORD_SCHEMA } from 'src/modules/profile/constants/schema';
import { changePassword } from 'src/modules/profile/sagas/utils/changePassword';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* profileChangePasswordSaga(
  action: GetRequestActionType<typeof PROFILE_CHANGE_PASSWORD>,
): SagaIterator<void> {
  const changePasswordForm = action.data;
  const changePasswordValues = changePasswordForm.values.get(changePasswordForm.currentState);

  const formValid = yield* call(formValidate, changePasswordForm, CHANGE_PASSWORD_SCHEMA);
  if (!formValid) {
    return;
  }

  try {
    yield* put(PROFILE_CHANGE_PASSWORD.pending());
    yield* call(logDebug, 'Changing password...');
    yield* call(formSubmitStart, changePasswordForm);

    yield* call(changePassword, changePasswordValues);

    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'profile/notification/passwordSaved',
      },
    );

    yield* call(logDebug, 'Changing password... done');
    yield* put(PROFILE_CHANGE_PASSWORD.success());
    yield* call(formSubmitSuccess, changePasswordForm);
    yield* call({ context: changePasswordForm, fn: changePasswordForm.reset }, changePasswordForm.initialState);

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Changing password... error', error);
    yield* put(PROFILE_CHANGE_PASSWORD.failure(NativeError.wrap(error)));

    yield* call(showNotification, { type: 'error', variant: 'error' });

    yield* call(formSubmitFailure, changePasswordForm, {
      '': {
        path: '',
        code: 'unknown',
        value: changePasswordValues,

        context: {},
        message: String(error),
      },
    });
  }
}
