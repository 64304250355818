import { SagaIterator } from 'redux-saga';
import { ORGANIZATION_SYNC_PERFORM } from 'src/modules/organization/actions/OrganizationActions';
import { getOrganizationSyncData } from 'src/modules/organization/selectors/getOrganizationSyncData';
import { call, select, take } from 'typed-redux-saga';

export function* waitOrganizationSync(): SagaIterator<void> {
  const saving = yield* select(getOrganizationSyncData);
  if (saving === null) {
    return;
  }
  if (saving.status.state === 'success') {
    return;
  }
  if (saving.status.state === 'failure') {
    throw saving.status.error;
  }

  yield* take([ORGANIZATION_SYNC_PERFORM.isSuccess, ORGANIZATION_SYNC_PERFORM.isFailure]);
  yield* call(waitOrganizationSync);
}
