import { FormController } from '@form-ts/core';
import { SyncMode } from 'src/modules/common/types/SyncMode';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { FormError } from 'src/modules/form/types/FormError';
import { User } from 'src/modules/users/types/User';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const USER_SAVE = createActions<
User,
User,
User
>('USER_SAVE');
export const USER_ADD = createActions<FormController<UserFormData, FormError>, User>('USER_ADD');
export const USER_DELETE = createActions<ReadonlyArray<User>, void, ReadonlyArray<User>>('USER_DELETE');

export const USER_SYNC_PERFORM = createActions<SyncMode, void>('USER_SYNC_PERFORM');
export const USER_SYNC_RESET = createTrigger<void>('USER_SYNC_RESET');
