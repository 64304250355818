import './Footer.scss';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';

export const Footer = ():React.ReactElement => {
  const intl = useIntl();
  const loggedInUser = useSelector(getLoggedInUser);

  const organization = loggedInUser.organization.office;

  const privacy = useMemo(
    () => (organization.shop === 'cn'
      ? 'https://www.belimo.com.cn/cn/zh_CN/privacy'
      : `https://www.belimo.com/${encodeURIComponent(organization.shop)}/en_US/privacy`),
    [organization],
  );
  const imprint = useMemo(
    () => (organization.shop === 'cn'
      ? 'https://www.belimo.com.cn/cn/zh_CN/imprint'
      : `https://www.belimo.com/${encodeURIComponent(organization.shop)}/en_US/imprint`),
    [organization],
  );

  const contacts = useMemo(() => `${organization.name}, ${
    organization.address.street
  }, ${
    organization.address.zip
  } ${
    organization.address.city
  } (${intl.formatMessage({ id: `common/country/name/${organization.address.country}` })})
`, [intl, organization]);

  return (
    <div className="bp-footer">
      <div className="bp-footer__links">
        <a
          href="mailto:retrofit.feedback@belimo.de"
          className="bp-footer__link"
          aria-label={intl.formatMessage({ id: 'common/footer/contact' })}
        >
          <FormattedMessage id="common/footer/contact"/>
        </a>

        {organization.shop !== 'hk' && (
          <a
            href={imprint}
            className="bp-footer__link"
            target="_blank"
            aria-label={intl.formatMessage({ id: 'common/footer/imprint' })}
            rel="noreferrer"
          >
            <FormattedMessage id="common/footer/imprint"/>
          </a>
        )}

        <a
          href={privacy}
          className="bp-footer__link"
          target="_blank"
          aria-label={intl.formatMessage({ id: 'common/footer/conditions' })}
          rel="noreferrer"
        >
          <FormattedMessage id="common/footer/conditions"/>
        </a>
      </div>

      <div className="bp-footer__address" aria-label={intl.formatMessage({ id: 'common/footer/address' })}>
        {contacts}
      </div>
    </div>
  );
};
