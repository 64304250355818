import { LogicError } from '@belimo-retrofit-portal/logic';

export function numberToUnit32(value: number, litteEndian: boolean): Uint8Array {
  if (!Number.isSafeInteger(value) || value < 0 || value > 0xFFFFFFFF) {
    throw new LogicError(`Number "${value}" can not be shown as UINT32`);
  }

  const view = new DataView(new ArrayBuffer(4), 0);
  view.setUint32(0, value, litteEndian);
  return new Uint8Array(view.buffer);
}
