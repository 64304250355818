/* eslint-disable indent */
import React from 'react';

export function htmlToReact(html: string): React.ReactNode {
  const document = new DOMParser().parseFromString(html, 'text/html');
  return parseChildren(document.body, false);
}

function processNode(node: Node): React.ReactNode {
  if (node instanceof Text) {
    return processTextNode(node);
  } if (node instanceof HTMLAnchorElement) {
    return processLinkElement(node);
  } if (node instanceof HTMLImageElement) {
    return processImageElement(node);
  } if (node instanceof HTMLElement) {
    return processGenericElement(node);
  }
  return null;
}

function processLinkElement(node: HTMLAnchorElement): React.ReactElement {
  return (
    <a
      href={node.href}
      hrefLang={node.hreflang || undefined}
      target={node.target || undefined}
      rel={node.rel || undefined}
    >
      {parseChildren(node, true)}
    </a>
  );
}

function processImageElement(node: HTMLImageElement): React.ReactElement {
  return (
    <img
      src={node.src}
      srcSet={node.srcset || undefined}
      sizes={node.sizes || undefined}
      alt={node.alt}
    />
  );
}

function processGenericElement(node: HTMLElement): React.ReactNode {
  const element = node.nodeName.toLowerCase();
  if (EMPTY_ELEMENTS.has(element)) {
    return React.createElement(element, {});
  }
  if (BASIC_ELEMENTS.has(element)) {
    return React.createElement(element, {}, parseChildren(node, true));
  }

  throw new Error(`Unsupported HTML tag "${node.nodeName}"`);
}

function processTextNode(node: Text): string {
  return node.textContent ?? '';
}

function parseChildren(node: HTMLElement, inner: boolean): React.ReactNode {
  if (node.childNodes.length === 0) {
    return undefined;
  }

  const childNodes = Array.from(node.childNodes).map((child) => processNode(child));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const wrappedNodes = inner ? childNodes : <>{ ...childNodes }</>;

  return childNodes.length === 1 ? childNodes[0] : wrappedNodes;
}

const EMPTY_ELEMENTS: ReadonlySet<string> = new Set([
  'br',
  'hr',
]);

const BASIC_ELEMENTS: ReadonlySet<string> = new Set([
  'ol',
  'ul',
  'li',

  'p',

  'span',

  'u',
  'i',
  's',
  'b',

  'em',
  'del',
  'small',
  'strong',

  'sub',
  'sup',
]);
