import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { ORGANIZATION } from 'src/modules/common/codecs/Organization';
import { Organization } from 'src/modules/common/types/Organization';
import { getOrganization } from 'src/modules/organization/selectors/getOrganization';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';
import { OrganizationRequestData } from 'src/modules/organization/types/OrganizationRequestData';
import { call, select } from 'typed-redux-saga';

export function* saveOrganizationForm(
  formData: OrganizationFormData,
): SagaIterator<Organization> {
  try {
    const organization = yield* select(getOrganization);
    const requestData: OrganizationRequestData = {
      logoId: formData.logo?.id ?? null,
      useOrganizationContacts: formData.useOrganizationContacts,

      address: formData.address,
      contact: formData.contact,
    };

    const { content } = yield* call(
      makeRestRequest,
      'PUT',
      `/organizations/${encodeURIComponent(organization.id)}`,
      requestData,
    );
    return yield* call(
      decodeOrThrow,
      ORGANIZATION,
      content,
    );
  } catch (error) {
    throw new RuntimeError('Could not save organization form', { formData }, NativeError.wrap(error));
  }
}
