import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { ASSET_UPLOAD } from 'src/modules/asset/actions/AssetActions';
import { uploadAsset } from 'src/modules/asset/sagas/utils/uploadAsset';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* assetUploadSaga(
  action: GetRequestActionType<typeof ASSET_UPLOAD>,
): SagaIterator<void> {
  const id = action.meta;
  const file = action.data;

  try {
    yield* put(ASSET_UPLOAD.pending(id));
    yield* call(logDebug, `Uploading asset "${id}"...`, { file });

    const asset = yield* call(uploadAsset, file);

    yield* call(logDebug, `Uploading asset "${id}"... done`, asset);
    yield* put(ASSET_UPLOAD.success(asset, id));

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(sentryCatch, error);
    yield* call(showNotification, { variant: 'error', type: 'error' });

    yield* call(logError, `Uploading asset "${id}"... error`, error);
    yield* put(ASSET_UPLOAD.failure(NativeError.wrap(error), id));
  }
}
