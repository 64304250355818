import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { call } from 'typed-redux-saga';

export function* resetFeedbackCounter(): SagaIterator<void> {
  try {
    yield* call(
      makeRestRequest,
      'PUT',
      '/account/reset-feedback-counter',
    );
  } catch (error) {
    throw new RuntimeError('Could not reset feedback counter', {}, NativeError.wrap(error));
  }
}
