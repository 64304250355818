import { createSelector } from 'reselect';
import { ProjectReportCoverContactsEnum } from 'src/modules/common/types/ProjectReportCoverContacts';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';

export const getCoverContactsOptions = createSelector([
  getLoggedInUser,
], (user) => (user.organization.useOrganizationContacts ? [
  ProjectReportCoverContactsEnum.ORGANIZATION,
  ProjectReportCoverContactsEnum.OFFICE,
] : [
  ProjectReportCoverContactsEnum.OFFICE,
]));
