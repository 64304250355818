import { SagaIterator } from 'redux-saga';
import { syncOrganizationForeground } from 'src/modules/organization/sagas/utils/syncOrganizationForeground';
import { waitOrganizationSync } from 'src/modules/organization/sagas/utils/waitOrganizationSync';
import { getOrganizationSyncData } from 'src/modules/organization/selectors/getOrganizationSyncData';
import { call, select } from 'typed-redux-saga';

export function* forceOrganizationSync(): SagaIterator<void> {
  // if synchronization is scheduled, new foreground task is triggered
  // to cancel all existing processes and to skip the planned delay
  const saving = yield* select(getOrganizationSyncData);
  if (saving?.status.state === 'pending') {
    yield* call(syncOrganizationForeground, saving.formData);
  } else {
    yield* call(waitOrganizationSync);
  }
}
