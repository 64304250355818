import { SagaIterator } from 'redux-saga';
import { FEEDBACK_RESET, FEEDBACK_SEND, FEEDBACK_UPDATE } from 'src/modules/feedback/actions/FeedbackActions';
import { feedbackResetSaga } from 'src/modules/feedback/sagas/feedbackResetSaga';
import { feedbackSendSaga } from 'src/modules/feedback/sagas/feedbackSendSaga';
import { feedbackUpdateSaga } from 'src/modules/feedback/sagas/feedbackUpdateSaga';
import { takeLatest } from 'typed-redux-saga';

export function* feedbackSaga(): SagaIterator<void> {
  yield* takeLatest(FEEDBACK_SEND.isRequest, feedbackSendSaga);
  yield* takeLatest(FEEDBACK_UPDATE.isRequest, feedbackUpdateSaga);
  yield* takeLatest(FEEDBACK_RESET.is, feedbackResetSaga);
}
