import { SagaIterator } from 'redux-saga';
import { syncUserForeground } from 'src/modules/users/sagas/utils/syncUserForeground';
import { waitUserSynchronization } from 'src/modules/users/sagas/utils/waitUserSynchronization';
import { getUserEditing } from 'src/modules/users/selectors/getUserEditing';
import { UserSnapshot } from 'src/modules/users/types/User';
import { call, select } from 'typed-redux-saga';

export function* forceUserSynchronization(
  users: ReadonlyArray<UserSnapshot>,
): SagaIterator<void> {
  // if synchronization is scheduled, new foreground task is triggered
  // to cancel all existing processes and to skip the planned delay
  const userEditing = yield* select(getUserEditing);
  if (userEditing?.status.state === 'pending') {
    yield* call(syncUserForeground, users);
  } else {
    yield* call(waitUserSynchronization);
  }
}
