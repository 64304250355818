import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { FEEDBACK_UPDATE } from 'src/modules/feedback/actions/FeedbackActions';
import { updateFeedbackCounter } from 'src/modules/feedback/sagas/utils/updateFeedbackCounter';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* feedbackUpdateSaga(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Update feedback...');
    yield* put(FEEDBACK_UPDATE.pending());

    const updatedUser = yield* call(updateFeedbackCounter);

    yield* put(FEEDBACK_UPDATE.success(updatedUser.feedbackCounter));

    yield* call(logDebug, 'Update feedback... done');
  } catch (error) {
    const wrapped = new RuntimeError('Could not update feedback', {}, NativeError.wrap(error));

    yield* call(logError, 'Update feedback... error', error);

    yield* put(FEEDBACK_UPDATE.failure(wrapped));
    yield* call(sentryCatch, wrapped);
  }
}
