import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { USER_SYNC_PERFORM } from 'src/modules/users/actions/UserActions';
import { deleteUser } from 'src/modules/users/sagas/utils/deleteUser';
import { updateUser } from 'src/modules/users/sagas/utils/updateUser';
import { getUsersListData } from 'src/modules/users/selectors/getUsersListData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { all, call, delay, put, select } from 'typed-redux-saga';

export function* userSyncPerformSaga(
  action: GetRequestActionType<typeof USER_SYNC_PERFORM>,
): SagaIterator<void> {
  const mode = action.data;

  try {
    yield* call(logDebug, `Synchronizing users in ${mode}...`);
    yield* put(USER_SYNC_PERFORM.pending(action.meta));

    if (mode === 'background') {
      yield* delay(1_000);
    }

    const userSnapshots = yield* select(getUsersListData);
    const usersToUpdate = userSnapshots
      .filter((userSnapshot) => userSnapshot.action === 'editing')
      .map((userSnapshot) => userSnapshot.user);
    const usersToDelete = userSnapshots
      .filter((userSnapshot) => userSnapshot.action === 'deleting')
      .map((userSnapshot) => userSnapshot.user);

    if (usersToUpdate.length === 0 && usersToDelete.length === 0) {
      return;
    }

    yield* all(usersToUpdate.map(
      (user) => callEffect(updateUser, user),
    ));

    yield* all(usersToDelete.map(
      (user) => callEffect(deleteUser, user.id),
    ));

    yield* call(logDebug, `Synchronizing users in ${mode}... done`);
    yield* put(USER_SYNC_PERFORM.success(action.meta, action.meta));
    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'admin/user/notification/save/success',
      },
    );

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(logError, `Synchronizing users in ${mode}... error`, error);
    yield* put(USER_SYNC_PERFORM.failure(NativeError.wrap(error), action.meta));
  }
}
