import { Action } from 'redux';
import { FEEDBACK_RESET, FEEDBACK_UPDATE } from 'src/modules/feedback/actions/FeedbackActions';
import { FEEDBACK_MODAL_COUNTER_THRESHOLD } from 'src/modules/feedback/constants/feedbackModal';
import { FeedbackState } from 'src/modules/feedback/types/FeedbackState';

export function feedbackCounterReducer(state: FeedbackState, action: Action): FeedbackState {
  if (FEEDBACK_UPDATE.isSuccess(action)) {
    return {
      ...state,
      feedbackCounter: action.data,
      feedbackModalOpened: action.data > FEEDBACK_MODAL_COUNTER_THRESHOLD,
    };
  }
  if (FEEDBACK_RESET.is(action)) {
    return {
      ...state,
      feedbackCounter: 0,
      feedbackModalOpened: false,
    };
  }

  return state;
}
