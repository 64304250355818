import { UUID } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { loadProject } from 'src/modules/common/sagas/utils/loadProject';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { PROJECT_VIEW_FETCH } from 'src/modules/project-view/actions/ProjectViewActions';
import { RouteMatch } from 'src/modules/routing/types/RouteMatch';
import { Transition } from 'src/modules/routing/types/Transition';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { assertDecode } from 'src/utils/assert';
import { call, put } from 'typed-redux-saga';

export function* fetchProjectDetails(transition: Transition, match: RouteMatch): SagaIterator<void> {
  const id = assertDecode(
    match.params.id,
    UUID,
    'Missing "id" route parameter',
    { transition, match },
  );

  try {
    yield* call(logDebug, `Fetching project "${id}" details...`);

    const project = yield* call(loadProject, id);
    const data = { project };

    yield* put(PROJECT_VIEW_FETCH.trigger(data, id));
    yield* call(logDebug, `Fetching project "${id}" details... done`, data);

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(logError, `Fetching project "${id}" details... error`, error);
    throw error;
  }
}
