import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { resetFeedbackCounter } from 'src/modules/feedback/sagas/utils/resetFeedbackCounter';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call } from 'typed-redux-saga';

export function* feedbackResetSaga(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Reset feedback...');

    yield* call(resetFeedbackCounter);

    yield* call(logDebug, 'Reset feedback... done');
  } catch (error) {
    const wrapped = new RuntimeError('Could not reset feedback', {}, NativeError.wrap(error));

    yield* call(logError, 'Reset feedback... error', error);

    yield* call(sentryCatch, wrapped);
  }
}
