import { SagaIterator } from 'redux-saga';
import {
  ORGANIZATION_SYNC_PERFORM,
  ORGANIZATION_SYNC_RESET,
} from 'src/modules/organization/actions/OrganizationActions';
import { organizationSyncPerformSaga } from 'src/modules/organization/sagas/organizationSyncPerformSaga';
import { cancel, take, takeLatest } from 'typed-redux-saga';

export function* organizationSaga(): SagaIterator<void> {
  while (true) {
    const task = yield* takeLatest(ORGANIZATION_SYNC_PERFORM.isRequest, organizationSyncPerformSaga);

    yield* take(ORGANIZATION_SYNC_RESET.is);
    yield* cancel(task);
  }
}
