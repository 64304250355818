import './ErrorModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import {
  MODAL_ORGANIZATION_ERROR_CLOSE,
  MODAL_ORGANIZATION_ERROR_RETRY,
} from 'src/modules/modals/actions/ModalActions';
import { ModalWindowOrganizationError } from 'src/modules/modals/types/ModalWindow';

type Props = {
  readonly modal: ModalWindowOrganizationError;
};

export const OrganizationErrorModal = ({ modal }: Props): React.ReactElement => {
  const intl = useIntl();
  const closeModal = useBoundAction(MODAL_ORGANIZATION_ERROR_CLOSE.trigger);
  const retry = useBoundAction(MODAL_ORGANIZATION_ERROR_RETRY.request, modal.data);

  const loading = modal.status?.state === 'pending';

  return (
    <>
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        labelClassName="bp-modal-wrapper__label"
        label={<FormattedMessage id="organizationErrorModal/title"/>}
      />

      <ModalBody className="bp-error-modal__body">
        <h2 className="bp-error-modal__body-title">
          <FormattedMessage id="organizationErrorModal/subtitle"/>
        </h2>

        <p className="bp-error-modal__body-description">
          <FormattedMessage id="organizationErrorModal/description"/>
        </p>
      </ModalBody>

      <ModalFooter>
        <CustomButton
          kind="secondary"
          onClick={closeModal}
          disabled={loading}
        >
          <FormattedMessage id="organizationErrorModal/close"/>
        </CustomButton>

        <CustomButton
          disabled={loading}
          kind="primary"
          onClick={retry}
          autoFocus={true}
          loading={loading}
        >
          <FormattedMessage id="organizationErrorModal/retry"/>
        </CustomButton>
      </ModalFooter>
    </>
  );
};
