import { SagaIterator } from 'redux-saga';
import { ORGANIZATION_SYNC_PERFORM } from 'src/modules/organization/actions/OrganizationActions';
import { waitOrganizationSync } from 'src/modules/organization/sagas/utils/waitOrganizationSync';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';
import { call, put } from 'typed-redux-saga';

export function* syncOrganizationForeground(formData: OrganizationFormData): SagaIterator<void> {
  yield* put(ORGANIZATION_SYNC_PERFORM.request('foreground', formData));
  yield* call(waitOrganizationSync);
}
