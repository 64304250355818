import { organizationSyncReducer } from 'src/modules/organization/reducers/organizationSyncReducer';
import { OrganizationState } from 'src/modules/organization/types/OrganizationState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: OrganizationState = {
  sync: null,
};

export const organizationReducer = chainReducers(defaultState, [
  organizationSyncReducer,
]);
