import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { SHARE_PROJECT_FETCH } from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { loadSharedProjectUsers } from 'src/modules/sharing-projects/sagas/utils/loadSharedProjectUsers';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* shareProjectFetchSaga(
  action: GetRequestActionType<typeof SHARE_PROJECT_FETCH>,
): SagaIterator<void> {
  const project = action.meta;

  try {
    yield* put(SHARE_PROJECT_FETCH.pending(project));
    yield* call(logDebug, 'Fetching project access list...', { project });

    const users = yield* call(loadSharedProjectUsers, project);

    yield* put(SHARE_PROJECT_FETCH.success(users, project));
    yield* call(logDebug, 'Fetching project access list... done', { project, users });

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Fetching project access list... error', { project, error });
    yield* put(SHARE_PROJECT_FETCH.failure(NativeError.wrap(error), project));
  }
}
