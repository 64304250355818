import { SagaIterator } from 'redux-saga';
import {
  MODAL_ORGANIZATION_ERROR_CLOSE,
  MODAL_ORGANIZATION_ERROR_OPEN,
} from 'src/modules/modals/actions/ModalActions';
import { ORGANIZATION_SYNC_RESET } from 'src/modules/organization/actions/OrganizationActions';
import { forceOrganizationSync } from 'src/modules/organization/sagas/utils/forceOrganizationSync';
import { getOrganizationSyncData } from 'src/modules/organization/selectors/getOrganizationSyncData';
import { call, put, select, take } from 'typed-redux-saga';

export function* syncOrganization(): SagaIterator<void> {
  const syncData = yield* select(getOrganizationSyncData);
  if (syncData === null) {
    return;
  }

  try {
    yield* call(forceOrganizationSync);

    // reset current synchronization status
    yield* put(ORGANIZATION_SYNC_RESET.trigger());
  } catch (error) {
    yield* put(MODAL_ORGANIZATION_ERROR_OPEN.trigger(syncData.formData));
    yield* take(MODAL_ORGANIZATION_ERROR_CLOSE.is);

    // reset current synchronization status
    yield* put(ORGANIZATION_SYNC_RESET.trigger());
  }
}
