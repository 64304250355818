import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { UPDATE_USER_SETTINGS } from 'src/modules/login/actions/UserSettingsActions';
import { updateUserSettings } from 'src/modules/login/sagas/utils/updateUserSettings';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call } from 'typed-redux-saga';

export function* updateUserSettingsSaga(action: GetTriggerActionType<typeof UPDATE_USER_SETTINGS>): SagaIterator<void> {
  const userSettings = action.data;

  try {
    yield* call(logDebug, 'Updating the user settings...');

    const user = yield* call(updateUserSettings, userSettings);

    yield* call(logDebug, 'Updating the user settings... done', user);
  } catch (error) {
    yield* call(logError, 'Updating the user settings... error', error);
    yield* call(sentryCatch, error);
  }
}
