import { Organization } from 'src/modules/common/types/Organization';
import { SyncMode } from 'src/modules/common/types/SyncMode';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const ORGANIZATION_SYNC_RESET =
  createTrigger<void>('ORGANIZATION_SYNC_RESET');
export const ORGANIZATION_SYNC_PERFORM =
  createActions<SyncMode, Organization, OrganizationFormData>('ORGANIZATION_SYNC_PERFORM');
