import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { USER_DELETE } from 'src/modules/users/actions/UserActions';
import { deleteUser } from 'src/modules/users/sagas/utils/deleteUser';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { all, call, put } from 'typed-redux-saga';

export function* userRemoveSaga(action: GetRequestActionType<typeof USER_DELETE>): SagaIterator<void> {
  try {
    yield* put(USER_DELETE.pending(action.data));
    yield* call(logDebug, 'Removing user...');

    yield* all(action.data.map(
      (user) => callEffect(deleteUser, user.id),
    ));

    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'admin/user/notification/remove/success',
      },
    );

    yield* call(logDebug, 'Removing user... done');
    yield* put(USER_DELETE.success(undefined, action.meta));

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(showNotification, { type: 'error', variant: 'error' });

    yield* call(logError, 'Removing user... error', error);
    yield* put(USER_DELETE.failure(NativeError.wrap(error), action.meta));
  }
}
