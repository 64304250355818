import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { numberToUnit32 } from 'src/modules/asset/utils/numberToUnit32';

export async function isValidImageData(file: File): Promise<boolean> {
  try {
    const data = await file.slice(0, 12).arrayBuffer();
    const view = new Uint8Array(data);

    for (const magicNumbers of getValidMagicNumbers(file)) {
      const match = magicNumbers.every((byte, index) => view[index] === byte);
      if (match) {
        return true;
      }
    }

    return false;
  } catch (error) {
    throw new RuntimeError('Could not validate image data', { file }, NativeError.wrap(error));
  }
}

function* getValidMagicNumbers(file: File): Iterable<Uint8Array> {
  // JPG
  yield new Uint8Array([
    0xFF, 0xD8,
  ]);

  // PNG
  yield new Uint8Array([
    0x89,
    0x50, 0x4E, 0x47,
    0x0D, 0x0A,
    0x1A,
    0x0A,
  ]);

  // WEBP
  yield new Uint8Array([
    0x52, 0x49, 0x46, 0x46,
    ...numberToUnit32(file.size - 8, true),
    0x57, 0x45, 0x42, 0x50,
  ]);
}
