import { SchemaArea } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { Project } from 'src/modules/common/types/Project';
import { UserSettings } from 'src/modules/common/types/UserSettings';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { ReportDocumentPageCover } from 'src/modules/report/views/document/ReportDocumentPageCover';
import { ReportDocumentPageEnergy } from 'src/modules/report/views/document/ReportDocumentPageEnergy';
import { ReportDocumentPageEvaluation } from 'src/modules/report/views/document/ReportDocumentPageEvaluation';
import { ReportDocumentPageHvac } from 'src/modules/report/views/document/ReportDocumentPageHvac';
import { ReportDocumentPageSavings } from 'src/modules/report/views/document/ReportDocumentPageSavings';
import { ReportDocumentPageSchema } from 'src/modules/report/views/document/ReportDocumentPageSchema';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly language: Language;
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
  readonly project: Project;
  readonly userSettings: UserSettings;
};

export const ReportDocumentContent = ({
  language,
  document,
  formData,
  project,
  userSettings,
}: Props): React.ReactElement => (
  <>
    <ReportDocumentPageCover
      document={document}
      formData={formData}
      language={language}
      userSettings={userSettings}
    />

    <ReportDocumentPageSavings
      document={document}
      formData={formData}
      language={language}
    />

    <ReportDocumentPageEvaluation
      document={document}
      formData={formData}
      project={project}
      language={language}
    />

    <ReportDocumentPageEnergy
      document={document}
      formData={formData}
      language={language}
    />

    <ReportDocumentPageHvac
      document={document}
      formData={formData}
      language={language}
    />

    {[
      SchemaArea.HEATING,
      SchemaArea.COOLING,
    ].map((area) => (
      <ReportDocumentPageSchema
        key={area}
        area={area}
        document={document}
        formData={formData}
        language={language}
      />
    ))}
  </>
);
