import { createSelector } from 'reselect';
import { getProjectLazy } from 'src/modules/common/selectors/getProjectLazy';
import { ProjectReportCoverContactsEnum } from 'src/modules/common/types/ProjectReportCoverContacts';
import { getCurrentDate } from 'src/modules/config/selectors/getCurrentDate';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { getReportFormData } from 'src/modules/report/selectors/getReportFormData';
import { ReportGeneratedCover } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedCover = createSelector([
  getProjectLazy,
  getReportFormData,
  getCurrentDate,
  getLoggedInUser,
], (project, report, currentDate, loggedInUser): ReportGeneratedCover => {
  const orgInfo = !loggedInUser.organization.useOrganizationContacts ||
  loggedInUser.settings.report.coverContacts === ProjectReportCoverContactsEnum.OFFICE
    ? loggedInUser.organization.office
    : loggedInUser.organization;

  return {
    title: project.data.title,
    goals: project.data.goals,
    image: project.data.cover,
    logo: loggedInUser.organization.logo,
    building: project.data.building,
    email: project.owner?.email ?? '',
    author: project.data.participants.author,
    reportDate: report.cover.creationDate ?? currentDate,
    organization: orgInfo.name,
    address: orgInfo.address,
    contact: orgInfo.contact,
  };
});
