import './OverflowMenu.scss';
import clsx from 'clsx';
import React, { memo, ReactNode, useCallback, useRef, useState } from 'react';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { Tooltip } from 'src/modules/common/components/Tooltip';
import { useOutsideClick } from 'src/modules/common/hooks/useOutsideClick';

type Props = {
  readonly buttonTooltip?: React.ReactNode;
  readonly interactive?: boolean;
  readonly label: ReactNode;
  readonly buttonSize?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  readonly children: ReactNode;
  readonly onClose?: () => void;
};

export const OverflowMenu = memo(({
  buttonTooltip,
  interactive = true,
  label,
  buttonSize,
  children,
  onClose,
}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [opened, setOpened] = useState(false);

  const handleButtonOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleButtonClose = useCallback(() => {
    if (opened) {
      setOpened(false);
      onClose?.();
    }
  }, [opened, onClose]);

  useOutsideClick(menuRef, handleButtonClose);

  return (
    <div
      ref={menuRef}
      className={clsx({
        'bp-overflow-menu': true,
        'bp-overflow-menu--opened': opened,
        'bp-overflow-menu--interactive': interactive,
      })}
    >
      <Tooltip value={buttonTooltip} disabled={opened}>
        <CustomButton
          kind="ghost"
          hasIconOnly={true}
          size={buttonSize}
          className="bp-overflow-menu__button"
          onClick={opened ? handleButtonClose : handleButtonOpen}
        >
          <div className="bp-overflow-menu__button-icon">
            {label}
          </div>
        </CustomButton>
      </Tooltip>

      <div className="bp-overflow-menu__list">
        {children}
      </div>
    </div>
  );
});
