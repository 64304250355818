import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { call } from 'typed-redux-saga';

export function* getImageContent(
  url: string,
): SagaIterator<ArrayBuffer> {
  try {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';
    yield* call([image, image.decode]);

    const canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    const context = canvas.getContext('2d')!;
    context.drawImage(image, 0, 0);

    const blob = yield* call(saveCanvas, canvas);
    return yield* call([blob, blob.arrayBuffer]);
  } catch (error) {
    throw new RuntimeError(`Could not get image content "${url}"`, {}, NativeError.wrap(error));
  }
}

function saveCanvas(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((data) => {
      if (data !== null) {
        resolve(data);
      } else {
        reject(new RuntimeError('Could not save canvas content'));
      }
    }, 'image/png', 1);
  });
}
