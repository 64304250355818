import { SagaIterator } from 'redux-saga';
import {
  ANNOUNCEMENTS_FETCH,
} from 'src/modules/announcements/actions/AnnouncementsActions';
import { loadAnnouncements } from 'src/modules/announcements/sagas/utils/loadAnnouncements';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* fetchAnnouncements(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching announcements...');

    const data = yield* call(loadAnnouncements);

    yield* put(ANNOUNCEMENTS_FETCH.trigger(data));
    yield* call(logDebug, 'Fetching announcements... done', data);

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(logError, 'Fetching announcements... error', error);
    throw error;
  }
}
