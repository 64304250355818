import { NativeError } from '@belimo-retrofit-portal/logic';
import { replace } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { INTEGER_ONE } from 'src/modules/common/constants/integer';
import { removeProject } from 'src/modules/common/sagas/utils/removeProject';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { PROJECT_LIST_FETCH, PROJECT_LIST_REMOVE_SUBMIT } from 'src/modules/project-list/actions/ProjectListActions';
import { loadProjectList } from 'src/modules/project-list/sagas/utils/loadProjectList';
import { getProjectListQuery } from 'src/modules/project-list/selectors/getProjectListQuery';
import { getProjectListRemoving } from 'src/modules/project-list/selectors/getProjectListRemoving';
import { ProjectListData } from 'src/modules/project-list/types/ProjectListData';
import { buildProjectListQuery } from 'src/modules/project-list/utils/query';
import { applyProjectListQuery } from 'src/modules/project-list/utils/slice';
import { navigate } from 'src/modules/routing/utils/navigate';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { assertNotNull } from 'src/utils/assert';
import { call, put, select } from 'typed-redux-saga';

export function* projectListRemoveSaga(): SagaIterator<void> {
  try {
    yield* put(PROJECT_LIST_REMOVE_SUBMIT.pending());
    yield* call(logDebug, 'Removing project...');

    const { project } = assertNotNull(
      yield* select(getProjectListRemoving),
      'Could not find project to remove',
    );

    yield* call(removeProject, project);

    const query = yield* select(getProjectListQuery);
    const projects = yield* call(loadProjectList, query);
    const data: ProjectListData = { projects, query };

    const authUser = yield* select(getLoggedInUser);
    const { found } = yield* call(applyProjectListQuery, data, authUser);

    if (found.length === 0) {
      const search = yield* call(buildProjectListQuery, { ...query, page: INTEGER_ONE });
      yield* call(navigate, replace({ pathname: '/projects', search: search.toString() }));
    } else {
      yield* put(PROJECT_LIST_FETCH.trigger(data));
    }

    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'projectList/notification/remove/success',
        messageValue: {
          projectName: project.data.title,
        },
      },
    );

    yield* call(logDebug, 'Removing project... done', project);
    yield* put(PROJECT_LIST_REMOVE_SUBMIT.success());

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(showNotification, { variant: 'error', type: 'error' });

    yield* call(logError, 'Removing project... error', error);
    yield* put(PROJECT_LIST_REMOVE_SUBMIT.failure(NativeError.wrap(error)));
  }
}
