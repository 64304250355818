import { useForm } from '@form-ts/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAction } from 'src/hooks/useAction';
import { CustomBreadcrumbItem } from 'src/modules/common/components/CustomBreadcrumbItem';
import { AdminLayout } from 'src/modules/common/layouts/AdminLayout';
import { FormError } from 'src/modules/form/types/FormError';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { ORGANIZATION_SYNC_PERFORM } from 'src/modules/organization/actions/OrganizationActions';
import { getOrganizationFormData } from 'src/modules/organization/selectors/getOrganizationFormData';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';
import { OrganizationSettings } from 'src/modules/organization/views/OrganizationSettings';

export const OrganizationPage = (): React.ReactElement => {
  const intl = useIntl();

  const user = useSelector(getLoggedInUser);
  const formData = useSelector(getOrganizationFormData);

  const form = useForm<OrganizationFormData, FormError>('admin.organization', {
    reinitialize: false,
    initialValues: formData,
  });
  const syncOrganization = useAction(ORGANIZATION_SYNC_PERFORM.request);

  useEffect(() => form.subscribe((prevState) => {
    if (prevState.values !== form.currentState.values) {
      syncOrganization('background', form.currentState.values);
    }
  }), [form, syncOrganization]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'admin/organization/title' })}</title>
      </Helmet>

      <AdminLayout
        breadcrumbs={(
          <CustomBreadcrumbItem>
            <Link to="/projects">
              {intl.formatMessage({ id: 'organization/settings/breadcrumbs/project' })}
            </Link>
          </CustomBreadcrumbItem>
        )}
        title={intl.formatMessage({ id: 'admin/organization/title' })}
      >
        <OrganizationSettings
          form={form}
          office={user.organization.office}
        />
      </AdminLayout>
    </>
  );
};
