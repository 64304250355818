import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import {
  MODAL_ORGANIZATION_ERROR_CLOSE,
  MODAL_ORGANIZATION_ERROR_RETRY,
} from 'src/modules/modals/actions/ModalActions';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncOrganizationForeground } from 'src/modules/organization/sagas/utils/syncOrganizationForeground';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* modalOrganizationErrorSaga(
  action: GetRequestActionType<typeof MODAL_ORGANIZATION_ERROR_RETRY>,
): SagaIterator<void> {
  const formData = action.data;

  try {
    yield* call(logDebug, 'Synchronizing organization...', formData);
    yield* put(MODAL_ORGANIZATION_ERROR_RETRY.pending());

    yield* call(syncOrganizationForeground, formData);
    yield* call(logDebug, 'Synchronizing organization... done', formData);

    yield* put(MODAL_ORGANIZATION_ERROR_RETRY.success());
    yield* put(MODAL_ORGANIZATION_ERROR_CLOSE.trigger());
  } catch (error) {
    const wrapped = new RuntimeError('Could not save synchronize organization', { formData }, NativeError.wrap(error));
    yield* call(logError, 'Synchronizing organization... error', error);
    yield* put(MODAL_ORGANIZATION_ERROR_RETRY.failure(wrapped));

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
