import { Project } from 'src/modules/common/types/Project';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';
import { UserSnapshot } from 'src/modules/users/types/User';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const MODAL_PROJECT_ERROR_OPEN = createTrigger<Project>('MODAL_PROJECT_ERROR_OPEN');
export const MODAL_PROJECT_ERROR_RETRY = createActions<Project, Project>('MODAL_PROJECT_ERROR_RETRY');
export const MODAL_PROJECT_ERROR_CLOSE = createTrigger<void>('MODAL_PROJECT_ERROR_CLOSE');

export const MODAL_USER_ERROR_OPEN = createTrigger<ReadonlyArray<UserSnapshot>>('MODAL_USER_ERROR_OPEN');
export const MODAL_USER_ERROR_RETRY = createActions<
ReadonlyArray<UserSnapshot>,
ReadonlyArray<UserSnapshot>,
ReadonlyArray<UserSnapshot>
>('MODAL_USER_ERROR_RETRY');
export const MODAL_USER_ERROR_CLOSE = createTrigger<void>('MODAL_USER_ERROR_CLOSE');

export const MODAL_ORGANIZATION_ERROR_OPEN =
  createTrigger<OrganizationFormData>('MODAL_ORGANIZATION_ERROR_OPEN');
export const MODAL_ORGANIZATION_ERROR_RETRY =
  createActions<OrganizationFormData, void>('MODAL_ORGANIZATION_ERROR_RETRY');
export const MODAL_ORGANIZATION_ERROR_CLOSE =
  createTrigger<void>('MODAL_ORGANIZATION_ERROR_CLOSE');
