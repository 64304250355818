import './RatingField.scss';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'src/migration/react-intl';
import { Rating } from 'src/modules/common/components/Rating';
import { FormError } from 'src/modules/form/types/FormError';

type Props<TData> = {
  readonly disabled: boolean;
  readonly field: FieldPath<TData, FormError, number | null>;
  readonly renderError?: (error: FormError) => React.ReactNode;
};

export const RatingField: <TData>(props: Props<TData>) => React.ReactElement = (
  {
    disabled,
    field,
    renderError = renderErrorDefault,
  },
) => {
  const { meta, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const handleChange = useCallback((newRating: number) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(newRating),
    ));
  }, [field]);

  return (
    <div className="bp-rating-field">
      <Rating error={error && invalid} onSelect={handleChange} disabled={disabled}/>

      {error && invalid ? renderError(error) : null}
    </div>
  );
};

const renderErrorDefault = (error: FormError): React.ReactNode => (
  <div className="bp-rating-field--error">
    <FormattedMessage id={`form/error/${error.code}`} values={error.context}/>
  </div>
);
