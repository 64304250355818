import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { ORGANIZATION_SYNC_PERFORM } from 'src/modules/organization/actions/OrganizationActions';
import { saveOrganizationForm } from 'src/modules/organization/sagas/utils/saveOrganizationForm';
import { syncUserOrganization } from 'src/modules/organization/sagas/utils/syncUserOrganization';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, delay, put } from 'typed-redux-saga';

export function* organizationSyncPerformSaga(
  action: GetRequestActionType<typeof ORGANIZATION_SYNC_PERFORM>,
): SagaIterator<void> {
  const mode = action.data;
  const form = action.meta;

  try {
    yield* call(logDebug, `Synchronizing organization in ${mode}...`, form);
    yield* put(ORGANIZATION_SYNC_PERFORM.pending(form));
    if (mode === 'background') {
      yield* delay(1_000);
    }

    const organization = yield* call(saveOrganizationForm, form);
    yield* call(syncUserOrganization, organization);

    yield* call(logDebug, `Synchronizing organization in ${mode}... done`, organization);
    yield* put(ORGANIZATION_SYNC_PERFORM.success(organization, form));

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);
  } catch (error) {
    yield* call(logError, `Synchronizing organization in ${mode}... error`, error);
    yield* put(ORGANIZATION_SYNC_PERFORM.failure(NativeError.wrap(error), form));
  }
}
