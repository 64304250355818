import { feedbackCounterReducer } from 'src/modules/feedback/reducers/feedbackCounterReducer';
import { feedbackModalReducer } from 'src/modules/feedback/reducers/feedbackModalReducer';
import { FeedbackState } from 'src/modules/feedback/types/FeedbackState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: FeedbackState = {
  feedbackCounter: 0,
  feedbackModalOpened: false,
};

export const feedbackReducer = chainReducers(defaultState, [
  feedbackCounterReducer,
  feedbackModalReducer,
]);
