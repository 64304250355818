import './FeedbackModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import { useForm, useFormWatch } from '@form-ts/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { FEEDBACK_SEND } from 'src/modules/feedback/actions/FeedbackActions';
import { FEEDBACK_FORM_DEFAULT_DATA } from 'src/modules/feedback/constants/formData';
import { FEEDBACK_FORM_SCHEMA } from 'src/modules/feedback/constants/schema';
import { FeedbackFormData } from 'src/modules/feedback/types/FeedbackFormData';
import { FeedbackForm } from 'src/modules/feedback/views/FeedbackForm';
import { FeedbackSent } from 'src/modules/feedback/views/FeedbackSent';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';

type Props = {
  readonly onClose: () => void;
};

export const FeedbackModal = ({ onClose }: Props): React.ReactElement => {
  const intl = useIntl();
  const form = useForm<FeedbackFormData, FormError>('feedback.form', {
    reinitialize: false,
    initialValues: FEEDBACK_FORM_DEFAULT_DATA,
  });

  useFormValidator(form, FEEDBACK_FORM_SCHEMA);

  const submitting = useFormWatch(form, form.submitting.get);

  const submitForm = useBoundAction(FEEDBACK_SEND.request, form);
  const submitSucceeded = useFormWatch(form, form.submitSucceded.get);

  return (
    <>
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        label={<FormattedMessage id="feedback/modal/label"/>}
        title={<FormattedMessage id="feedback/modal/title"/>}
        closeModal={onClose}
      />

      <ModalBody>
        <div className="bp-feedback-modal__body">
          <div className="bp-feedback-modal__slogan">
            <img
              src="/images/FeedbackPicture.svg"
              alt=""
              width="238"
            />
          </div>

          <div className="bp-feedback-modal__form">
            {submitSucceeded
              ? <FeedbackSent/>
              : <FeedbackForm form={form}/>}
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <CustomButton
          className="bp-feedback-form__button"
          kind="secondary"
          onClick={onClose}
          autoFocus={true}
          disabled={submitting}
        >
          {submitSucceeded
            ? <FormattedMessage id="feedback/modal/sent/close"/>
            : <FormattedMessage id="feedback/modal/form/cancel"/>}
        </CustomButton>

        {!submitSucceeded && (
          <CustomButton
            disabled={submitting}
            kind="primary"
            loading={submitting}
            onClick={submitForm}
            renderIcon={() => (
              <div className="bp-feedback-form__button-icon">
                <ArrowRightIcon/>
              </div>
            )}
          >
            <FormattedMessage id="feedback/modal/form/submit"/>
          </CustomButton>
        )}
      </ModalFooter>
    </>
  );
};
