import { FormController } from '@form-ts/core';
import { FeedbackFormData } from 'src/modules/feedback/types/FeedbackFormData';
import { FormError } from 'src/modules/form/types/FormError';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const FEEDBACK_SEND = createActions<FormController<FeedbackFormData, FormError>, void>('FEEDBACK_SEND');
export const FEEDBACK_UPDATE = createActions<void, number>('FEEDBACK_UPDATE');
export const FEEDBACK_RESET = createTrigger<void>('FEEDBACK_RESET');

export const FEEDBACK_OPEN = createTrigger<boolean>('FEEDBACK_OPEN');
