import './OrganizationSettings.scss';
import { CountryCode } from '@belimo-retrofit-portal/logic';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import { constNull } from 'fp-ts/function';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TileCard } from 'src/modules/common/components/TileCard';
import { useCountryList } from 'src/modules/common/hooks/useCountryList';
import { OrganizationOffice } from 'src/modules/common/types/OrganizationOffice';
import { AssetField } from 'src/modules/form/components/AssetField';
import { AutocompleteField } from 'src/modules/form/components/AutocompleteField';
import { TextField } from 'src/modules/form/components/TextField';
import { ToggleField } from 'src/modules/form/components/ToggleField';
import { FormError } from 'src/modules/form/types/FormError';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';

type Props = {
  readonly office: OrganizationOffice;
  readonly form: FormController<OrganizationFormData, FormError>;
};

export const OrganizationSettings = ({ office, form }: Props): React.ReactElement => {
  const countryCodes = useCountryList();
  const useOrganizationContacts = useFormWatch(form, form.field.at('useOrganizationContacts').value.get);

  return (
    <form className="bp-organization-settings" onSubmit={preventDefault}>
      <div className="bp-organization-settings__content">
        <div className="bp-organization-settings__contacts">
          <TileCard title={<FormattedMessage id="organization/settings/contacts/title"/>}>
            <div className="bp-organization-settings__row">
              <ToggleField
                field={form.field.at('useOrganizationContacts')}
                label={<FormattedMessage id="organization/settings/form/sameAsOffice"/>}
              />
            </div>
            <div className="bp-organization-settings__row">
              <TextField
                size="lg"
                field={form.field.at('name')}
                disabled={true}
                labelText={<FormattedMessage id="organization/settings/form/name"/>}
                autoComplete="off"
                maxLength={30}
                renderHint={constNull}
              />
            </div>
            <div className="bp-organization-settings__row">
              <TextField
                size="lg"
                field={form.field.at('address').at('street')}
                disabled={!useOrganizationContacts}
                labelText={<FormattedMessage id="organization/settings/form/street"/>}
                autoComplete="off"
                maxLength={30}
                renderHint={constNull}
              />

              <TextField
                size="lg"
                field={form.field.at('address').at('city')}
                disabled={!useOrganizationContacts}
                labelText={<FormattedMessage id="organization/settings/form/city"/>}
                autoComplete="off"
                maxLength={20}
                renderHint={constNull}
              />
            </div>
            <div className="bp-organization-settings__row">
              <AutocompleteField
                size="lg"
                direction="top"
                field={form.field.at('address').at('country')}
                disabled={true}
                titleText={<FormattedMessage id="organization/settings/form/country"/>}
                options={countryCodes}
                itemToElement={renderCountryOption}
              />

              <TextField
                size="lg"
                field={form.field.at('address').at('zip')}
                disabled={!useOrganizationContacts}
                labelText={<FormattedMessage id="organization/settings/form/zip"/>}
                autoComplete="off"
                maxLength={10}
                renderHint={constNull}
              />

              <TextField
                size="lg"
                field={form.field.at('contact').at('phone')}
                disabled={!useOrganizationContacts}
                labelText={<FormattedMessage id="organization/settings/form/phone"/>}
                autoComplete="off"
                maxLength={30}
                renderHint={constNull}
              />
            </div>
            <div className="bp-organization-settings__row">
              <TextField
                size="lg"
                field={form.field.at('contact').at('email')}
                disabled={!useOrganizationContacts}
                labelText={<FormattedMessage id="organization/settings/form/email"/>}
                autoComplete="off"
                maxLength={30}
                renderHint={constNull}
              />

              <TextField
                size="lg"
                field={form.field.at('contact').at('website')}
                disabled={!useOrganizationContacts}
                labelText={<FormattedMessage id="organization/settings/form/website"/>}
                autoComplete="off"
                maxLength={30}
                renderHint={constNull}
              />
            </div>
          </TileCard>
        </div>

        <div className="bp-organization-settings__office">
          <TileCard title={<FormattedMessage id="organization/settings/office/title"/>}>
            <div className="bp-organization-settings__office-contents">
              <div className="bp-organization-settings__contact">
                <div className="bp-organization-settings__contact-title">
                  <FormattedMessage id="organization/settings/form/name"/>
                </div>

                <div className="bp-organization-settings__contact-info">
                  {office.name}
                </div>
              </div>

              <div className="bp-organization-settings__contact">
                <div className="bp-organization-settings__contact-title">
                  <FormattedMessage id="organization/settings/form/street"/>
                </div>

                <div className="bp-organization-settings__contact-info">
                  {office.address.street}
                </div>
              </div>

              <div className="bp-organization-settings__contact">
                <div className="bp-organization-settings__contact-title">
                  <FormattedMessage id="organization/settings/form/city"/>
                </div>

                <div className="bp-organization-settings__contact-info">
                  {office.address.city}
                </div>
              </div>

              <div className="bp-organization-settings__contact">
                <div className="bp-organization-settings__contact-title">
                  <FormattedMessage id="organization/settings/form/country"/>
                </div>

                <div className="bp-organization-settings__contact-info">
                  <FormattedMessage id={`common/country/name/${office.address.country}`}/>
                </div>
              </div>

              <div className="bp-organization-settings__contact">
                <div className="bp-organization-settings__contact-title">
                  <FormattedMessage id="organization/settings/form/zip"/>
                </div>

                <div className="bp-organization-settings__contact-info">
                  {office.address.zip}
                </div>
              </div>

              <div className="bp-organization-settings__contact">
                <div className="bp-organization-settings__contact-title">
                  <FormattedMessage id="organization/settings/form/phone"/>
                </div>

                <div className="bp-organization-settings__contact-info">
                  {office.contact.phone}
                </div>
              </div>

              <div className="bp-organization-settings__contact">
                <div className="bp-organization-settings__contact-title">
                  <FormattedMessage id="organization/settings/form/email"/>
                </div>

                <div className="bp-organization-settings__contact-info">
                  {office.contact.email}
                </div>
              </div>

              <div className="bp-organization-settings__contact">
                <div className="bp-organization-settings__contact-title">
                  <FormattedMessage id="organization/settings/form/website"/>
                </div>

                <div className="bp-organization-settings__contact-info">
                  {office.contact.website}
                </div>
              </div>
            </div>
          </TileCard>
        </div>

        <div className="bp-organization-settings__logo">
          <TileCard title={<FormattedMessage id="organization/settings/logo/title"/>}>
            <FormattedMessage id="organization/settings/logo/label"/>

            <AssetField
              field={form.field.at('logo')}
            />
          </TileCard>
        </div>
      </div>
    </form>
  );
};

function renderCountryOption(option: CountryCode | null): React.ReactNode {
  return option ? <FormattedMessage id={`common/country/name/${option}`}/> : null;
}

function preventDefault(event: React.FormEvent<HTMLFormElement>): void {
  event.preventDefault();
}
