import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { redirectTo } from 'src/modules/routing/hooks/resolvers/redirectTo';
import { createStateTransitionLoggingSaga } from 'src/modules/routing/hooks/stateTransitionLogging';
import { RouteState } from 'src/modules/routing/types/RouteState';
import { composeSagas } from 'src/modules/routing/utils/composeSagas';

export const ROUTING_HOME_STATES: ReadonlyArray<RouteState> = [
  {
    url: '/',
    name: 'bp:home',
    parent: 'bp:authorized',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      redirectTo({ pathname: '/projects' }),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      scheduleUpdateLatestActivity,
      scheduleUpdateFeedbackCounter,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
];
