import { Action } from 'redux';
import { UPDATE_USER_SETTINGS } from 'src/modules/login/actions/UserSettingsActions';
import { LoginState } from 'src/modules/login/types/LoginState';

export function userSettingsReducer(state: LoginState, action: Action): LoginState {
  if (UPDATE_USER_SETTINGS.is(action)) {
    return {
      ...state,
      user: state.user
        ? {
          ...state.user,
          settings: action.data,
        }
        : null,
    };
  }

  return state;
}
