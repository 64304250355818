import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { T, useTranslate } from '@tolgee/react';
import { useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { htmlToReact } from 'src/modules/common/utils/htmlToReact';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';

export type PrimitiveType =
  | string
  | number
  | boolean
  | null
  | undefined
  | Date;

export type MessageDescriptor = {
  readonly id: string;
};

export type FormatIcuElement = {
  (node: React.ReactNode): React.ReactNode;
};

export type StringMessageValues = Readonly<Record<string, PrimitiveType>>;

export type ElementMessageValues = Readonly<Record<string, PrimitiveType | FormatIcuElement | React.ReactNode>>;

export type FormattedMessageProps = MessageDescriptor & {
  readonly values?: ElementMessageValues;
};

export type FormattedDateProps = {
  readonly value: Date;
};

export type IntlInstance = {
  readonly formatMessage: (descriptor: MessageDescriptor, values?: StringMessageValues) => string;
};

export type WrappedComponentProps<TProps> = TProps & {
  readonly intl: IntlInstance;
};

export const FormattedMessage = ({ id, values }: FormattedMessageProps): React.ReactElement => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  <T keyName={id} params={values}/>
);

export const FormattedHTMLMessage = ({ id }: MessageDescriptor): React.ReactElement => {
  const { t } = useTranslate();

  const children = useMemo(() => parseHtml(t(id, id, { noWrap: true }), id), [id, t]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

function parseHtml(html: string, id: string): React.ReactNode {
  try {
    return htmlToReact(html);
  } catch (error) {
    const wrapped = new RuntimeError(`Invalid HTML message content "${id}"`, { id, html }, NativeError.wrap(error));
    if (process.env.NODE_ENV === 'development') {
      throw wrapped;
    }

    sentryCatch(wrapped);
    return html;
  }
}

export const FormattedDate = ({ value }: FormattedDateProps): React.ReactElement => {
  const language = useSelector(getLanguage);

  const formatted = new Intl.DateTimeFormat(language, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }).format(value);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formatted}</>;
};

export function useIntl(): IntlInstance {
  const { t } = useTranslate();

  return useMemo((): IntlInstance => ({
    formatMessage: (descriptor, values) => t(descriptor.id, values),
  }), [t]);
}

export function injectIntl<TProps>(
  Component: React.ComponentType<WrappedComponentProps<TProps>>,
): React.FunctionComponent<TProps> {
  return (props: TProps): React.ReactElement => {
    const intl = useIntl();
    return <Component intl={intl} {...props}/>;
  };
}
