import './ReportFormSectionConfig.scss';
import { FieldPath } from '@form-ts/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomToggle } from 'src/modules/common/components/CustomToggle';
import { Dropdown } from 'src/modules/common/components/Dropdown';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { Project } from 'src/modules/common/types/Project';
import { ProjectReportCoverContactsEnum } from 'src/modules/common/types/ProjectReportCoverContacts';
import { UserSettings } from 'src/modules/common/types/UserSettings';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { DateboxField } from 'src/modules/form/components/DateboxField';
import { FormError } from 'src/modules/form/types/FormError';
import { getCoverContactsOptions } from 'src/modules/report/selectors/getCoverContactsOptions';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { ReportFormFieldToggle } from 'src/modules/report/views/ReportFormFieldToggle';

type Props = {
  readonly field: FieldPath<ReportFormData, FormError, ReportFormData['cover']>;
  readonly project: Project;
  readonly document: ReportGenerated;
  readonly userSettings: UserSettings;
  readonly useOrganizationContacts: boolean;
  readonly updateUserSettings: (data: UserSettings) => void;
};

export const ReportFormSectionConfigCover = ({
  field,
  project,
  document,
  userSettings,
  useOrganizationContacts,
  updateUserSettings,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const officeContactsOptions = useSelector(getCoverContactsOptions);

  return (
    <div className="bp-report-form-section-config">
      <div className="bp-report-form-section-config__header">
        <LeadText className="bp-report-form-section-config__header-title">
          <FormattedMessage id="report/form/cover/header"/>
        </LeadText>
      </div>

      <HorizontalDivider/>

      <div className="bp-report-form-section-config__content">
        <ReportFormFieldToggle field={field.at('showBuildingInfo')}>
          <FormattedMessage id="report/form/cover/showBuildingInfo"/>
        </ReportFormFieldToggle>

        <HorizontalDivider/>

        <CustomToggle
          id="report/form/cover/showOrganizationLogo"
          toggled={userSettings.report.showOrganizationLogo}
          disabled={document.cover.logo === null}
          onToggle={(checked) => updateUserSettings({
            report: { ...userSettings.report, showOrganizationLogo: checked },
          })}
          labelA={intl.formatMessage({ id: 'report/form/cover/showOrganizationLogo' })}
          labelB={intl.formatMessage({ id: 'report/form/cover/showOrganizationLogo' })}
          labelText={undefined}
        />

        <HorizontalDivider/>

        <div className="bp-report-form-section-config__empty-divider"/>

        <DateboxField
          size="md"
          field={field.at('creationDate')}
          label={intl.formatMessage({ id: 'report/form/cover/reportDate' })}
        />

        <Dropdown
          options={officeContactsOptions}
          renderLabel={renderAllowContactsOption}
          selectedValue={useOrganizationContacts
            ? userSettings.report.coverContacts
            : ProjectReportCoverContactsEnum.OFFICE}
          onChange={(value) => updateUserSettings({ report: { ...userSettings.report, coverContacts: value } })}
          outlined={true}
          disabled={!useOrganizationContacts}
        />
      </div>

      <div className="bp-report-form-section-config__action">
        <CustomButton
          fullWidth={true}
          as={Link}
          to={`/project/${encodeURIComponent(project.id)}`}
          kind="tertiary"
          size="md"
        >
          <FormattedMessage id="report/form/cover/editLinkText"/>

          <div className="bp-report-form-section-config__button-icon">
            <ArrowRightIcon/>
          </div>
        </CustomButton>
      </div>
    </div>
  );
};

function renderAllowContactsOption(option: string): React.ReactElement {
  return <FormattedMessage id={`report/form/cover/contacts/${option}`} values={{ number: option }}/>;
}
