import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import {
  MODAL_ORGANIZATION_ERROR_CLOSE,
  MODAL_ORGANIZATION_ERROR_OPEN,
  MODAL_ORGANIZATION_ERROR_RETRY,
} from 'src/modules/modals/actions/ModalActions';
import { ModalStackState } from 'src/modules/modals/types/ModalStackState';

export function modalOrganizationErrorReducer(state: ModalStackState, action: Action): ModalStackState {
  if (MODAL_ORGANIZATION_ERROR_OPEN.is(action)) {
    return state.filter((it) => it.type !== 'organization-error').concat({
      type: 'organization-error',
      data: action.data,
      status: null,
    });
  }
  if (MODAL_ORGANIZATION_ERROR_CLOSE.is(action)) {
    return state.filter((it) => it.type !== 'organization-error');
  }

  if (MODAL_ORGANIZATION_ERROR_RETRY.isPending(action)) {
    return state.map((it) => (
      it.type === 'organization-error'
        ? { ...it, status: FUTURE.pending() }
        : it
    ));
  }
  if (MODAL_ORGANIZATION_ERROR_RETRY.isSuccess(action)) {
    return state.map((it) => (
      it.type === 'organization-error'
        ? { ...it, status: FUTURE.success(undefined) }
        : it
    ));
  }
  if (MODAL_ORGANIZATION_ERROR_RETRY.isFailure(action)) {
    return state.map((it) => (
      it.type === 'organization-error'
        ? { ...it, status: FUTURE.failure(action.data) }
        : it
    ));
  }

  return state;
}
