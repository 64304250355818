import { SagaIterator } from 'redux-saga';
import {
  MODAL_ORGANIZATION_ERROR_RETRY,
  MODAL_PROJECT_ERROR_RETRY,
  MODAL_USER_ERROR_RETRY,
} from 'src/modules/modals/actions/ModalActions';
import { modalOrganizationErrorSaga } from 'src/modules/modals/sagas/modalOrganizationErrorSaga';
import { modalProjectErrorSaga } from 'src/modules/modals/sagas/modalProjectErrorSaga';
import { modalUserErrorSaga } from 'src/modules/modals/sagas/modalUserErrorSaga';
import { takeLatest } from 'typed-redux-saga';

export function* modalStackSaga(): SagaIterator<void> {
  yield* takeLatest(MODAL_PROJECT_ERROR_RETRY.isRequest, modalProjectErrorSaga);
  yield* takeLatest(MODAL_USER_ERROR_RETRY.isRequest, modalUserErrorSaga);
  yield* takeLatest(MODAL_ORGANIZATION_ERROR_RETRY.isRequest, modalOrganizationErrorSaga);
}
