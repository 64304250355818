import { NativeError, ProjectData, resolveInitialProjectData } from '@belimo-retrofit-portal/logic';
import { replace } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { uploadAsset } from 'src/modules/asset/sagas/utils/uploadAsset';
import { createProject } from 'src/modules/common/sagas/utils/createProject';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { scheduleUpdateFeedbackCounter } from 'src/modules/feedback/sagas/utils/scheduleUpdateFeedbackCounter';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { scheduleUpdateLatestActivity } from 'src/modules/profile/sagas/utils/scheduleUpdateLatestActivity';
import { PROJECT_NEW_CREATE } from 'src/modules/project-new/actions/ProjectNewActions';
import {
  PROJECT_CURRENCY_DEFAULT_DATA,
  PROJECT_EVALUATION_DEFAULT_DATA,
  PROJECT_HVAC_DEFAULT_DATA,
  PROJECT_PARTICIPANTS_DEFAULT_DATA,
  PROJECT_REPORT_DEFAULT_DATA,
  PROJECT_SCHEMA_DEFAULT_DATA,
  PROJECT_VERSION,
} from 'src/modules/project-new/constants/projectData';
import { getRandomDefaultCover } from 'src/modules/project-new/utils/getRandomDefaultCover';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { assertNotNull } from 'src/utils/assert';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* projectNewCreateSaga(
  action: GetRequestActionType<typeof PROJECT_NEW_CREATE>,
): SagaIterator<void> {
  const basicValues = action.data.stepOne;
  const buildingForm = action.data.stepTwo;

  const buildingValues = buildingForm.values.get(buildingForm.currentState);

  try {
    yield* put(PROJECT_NEW_CREATE.pending());
    yield* call(logDebug, 'Creating new project...');
    yield* call(formSubmitStart, buildingForm);

    const cover = basicValues.cover !== null
      ? basicValues.cover
      : yield* call(getRandomDefaultCover);

    const asset = yield* call(uploadAsset, cover);

    const user = yield* select(getLoggedInUser);

    const projectData: ProjectData = yield* call(resolveInitialProjectData, {
      version: PROJECT_VERSION,
      title: basicValues.title,
      goals: basicValues.projectGoals,
      cover: asset,
      building: {
        type: assertNotNull(
          buildingValues.type,
          'Building type is not defined',
          { buildingValues },
        ),
        address: {
          country: assertNotNull(
            buildingValues.address.country,
            'Country is not defined',
            { buildingValues },
          ),
          city: buildingValues.address.city,
          street: buildingValues.address.street,
          zip: buildingValues.address.zip,
        },
        size: buildingValues.size,
        unit: buildingValues.unit,
      },
      participants: {
        ...PROJECT_PARTICIPANTS_DEFAULT_DATA,
        author: `${user.firstName} ${user.lastName}`,
      },
      hvac: PROJECT_HVAC_DEFAULT_DATA,
      currency: PROJECT_CURRENCY_DEFAULT_DATA,
      schema: PROJECT_SCHEMA_DEFAULT_DATA,
      report: PROJECT_REPORT_DEFAULT_DATA,
      evaluation: PROJECT_EVALUATION_DEFAULT_DATA,
    });

    const project = yield* call(createProject, projectData);
    yield* put(replace(`/project/${encodeURIComponent(project.id)}`));

    yield* call(logDebug, 'Creating new project... done', project);
    yield* put(PROJECT_NEW_CREATE.success());

    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'project/create/success',
        messageValue: {
          projectName: basicValues.title,
        },
      },
    );

    yield* call(scheduleUpdateLatestActivity);
    yield* call(scheduleUpdateFeedbackCounter);

    // no need to unlock the form until router transition is completed
    // so there is no `formSubmitSuccess` call
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Creating new project... error', error);
    yield* put(PROJECT_NEW_CREATE.failure(NativeError.wrap(error)));

    yield* call(showNotification, { variant: 'error', type: 'error' });

    yield* call(formSubmitFailure, buildingForm, {
      '': {
        path: '',
        code: 'unknown',
        value: buildingValues,

        message: String(error),
        context: {},
      },
    });
  }
}
