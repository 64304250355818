import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import {
  ORGANIZATION_SYNC_PERFORM,
  ORGANIZATION_SYNC_RESET,
} from 'src/modules/organization/actions/OrganizationActions';
import { OrganizationState } from 'src/modules/organization/types/OrganizationState';

export function organizationSyncReducer(state: OrganizationState, action: Action): OrganizationState {
  if (ORGANIZATION_SYNC_RESET.is(action)) {
    return { ...state, sync: null };
  }

  if (ORGANIZATION_SYNC_PERFORM.isRequest(action)) {
    return {
      ...state,
      sync: {
        formData: action.meta,
        status: FUTURE.pending(),
      },
    };
  }
  if (ORGANIZATION_SYNC_PERFORM.isPending(action)) {
    return {
      ...state,
      sync: {
        formData: action.meta,
        status: FUTURE.pending(),
      },
    };
  }
  if (ORGANIZATION_SYNC_PERFORM.isSuccess(action)) {
    return {
      ...state,
      sync: {
        formData: action.data,
        status: FUTURE.success(undefined),
      },
    };
  }
  if (ORGANIZATION_SYNC_PERFORM.isFailure(action)) {
    return {
      ...state,
      sync: {
        formData: action.meta,
        status: FUTURE.failure(action.data),
      },
    };
  }

  return state;
}
