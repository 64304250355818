import { Action } from 'redux';
import { FEEDBACK_OPEN } from 'src/modules/feedback/actions/FeedbackActions';
import { FeedbackState } from 'src/modules/feedback/types/FeedbackState';

export function feedbackModalReducer(state: FeedbackState, action: Action): FeedbackState {
  if (FEEDBACK_OPEN.is(action)) {
    return {
      ...state,
      feedbackModalOpened: action.data,
    };
  }

  return state;
}
